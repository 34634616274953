import {
  DeleteOutlined,
  EyeFilled,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import ImageUploader from "../../UI/ImageUploader/ImageUploader";
import "../../Common/Common.css";
import {
  AttachmentsCategory,
  CandidateRequestType,
  IndianState,
  languageProficiency,
  MasterTypes,
  nationalityOption,
  StudyModeObj,
} from "../../Constant/Enums";
import {
  convertBasicDetailsToJson,
  convertToAntForm,
} from "../EnquiryFormUtil";
import HTTPSCalls from "../../Services/HTTPCalls";
import { StateMethods } from "@hookstate/core";
import ViewStudentDetails from "./ViewStudentDetails";
const { Text, Link } = Typography;
interface IBasicStudentDetails {
  apiData: any;
  isEdit: boolean;
  editOperation:
    | {
        key: string;
        onSave: () => void;
        onCancel: () => void;
      }
    | null
    | undefined;
  setEditOperation: any;
  studentSignature: any;
  studentImage: any;
  counselorSignature: any;
  setStudentSignature: any;
  setStudentImage: any;
  setCounselorSignature: any;
  saveLoading: boolean;
  setSaveLoading: any;
}

const BasicStudentDetails = ({
  apiData,
  editOperation,
  setEditOperation,
  isEdit,
  counselorSignature,
  studentSignature,
  studentImage,
  setStudentSignature,
  setCounselorSignature,
  setStudentImage,
  saveLoading,
  setSaveLoading,
}: IBasicStudentDetails) => {
  const [form] = Form.useForm();
  const [refresh, setRefresh] = useState(false);
  const [clear, setClear] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [isDataPost, setIsDataPost] = useState(false);
  const [currentprevAddress, setCurrentprevAddress] = useState("");
  const [masterData, setMasterData] = useState<{
    gender: any;
    curriculum: any;
    classes: any;
    streams: any;
    category: any;
    credentials: any;
    document: any;
    status: any;
    batch: any;
    medium: any;
  }>({
    gender: [],
    curriculum: [],
    classes: [],
    streams: [],
    category: [],
    credentials: [],
    document: [],
    status: [],
    batch: [],
    medium: [],
  });
  const [counselors, setCounselors] = useState([{}]);
  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_USER_LIST + "?", {
      roleSearch: "ADMIN",
    });
    if (res?.result?.items?.length > 0) {
      setCounselors(
        res?.result?.items?.map((item: any) => ({
          value: item?.id,
          label: item?.name?.first,
          // role: item?.role,
        }))
      );
    }
  };
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      let obj: {
        gender: any;
        curriculum: any;
        classes: any;
        streams: any;
        category: any;
        credentials: any;
        document: any;
        status: any;
        batch: any;
        medium: any;
      } = {
        gender: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Gender
        )?.[0]?.items,
        curriculum: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Curriculum
        )?.[0]?.items,
        classes: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Classes
        )?.[0]?.items,
        streams: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Stream
        )?.[0]?.items,
        category: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Category
        )?.[0]?.items,
        credentials: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Credential
        )?.[0]?.items,
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
        status: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Status
        )?.[0]?.items,
        batch: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Batch
        )?.[0]?.items,
        medium: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Medium
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };

  const validateCurrentPassOutYear = async (rule: any, val: any) => {
    if (val < 2000) throw new Error("Current PassOut Year >= 2000");
    if (val > new Date().getFullYear()) {
      throw new Error(`Current PassOut <=${new Date().getFullYear()}`);
    }
  };

  const percentageValid =  (rule: any, val: any) => {
    if (val > 100) throw new Error("Please enter valid percentage %");
    // return 
  };

  const validatePercentage = (rule:any, value:any) => {
    return new Promise((resolve, reject) => {
      if (value > 100) {
        reject("Please enter valid percentage %");
      } else {
        resolve("Resolve");
      }
    });
  };
  const handleSubmit = (validateFields: boolean = false) => {
    if (validateFields) {
      form.validateFields().then(async (val: any) => {
        // setButtonLoading(true);
        console.log(val,val);
        
        let formData = convertBasicDetailsToJson(
          {
            ...apiData,
            ...val,
            contactDetail:{
              currentAddress:val?.contactDetail?.currentAddress,
              permanentAddress:val?.contactDetail?.permanentAddress,
              email:val?.contactDetail?.email,
              mobileNumber:val?.contactDetail?.mobileNumber,
              emergencyNumber:val?.contactDetail?.emergencyNumber
            },
          },
          apiData?.id,
          [],
          apiData?.attachments,
          studentSignature,
          counselorSignature,
          studentImage
        );
        console.log(val,"submittedValues11111111111111111111111 = IF", formData);
        setSaveLoading(true);
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.ENQUIRY_ADD + "?",
          { type: CandidateRequestType.Student },
          { ...formData }
        );
        if (response.status) {
          setSaveLoading(false);
          // resetForm();
          // setButtonLoading(false);
          // resetForm();
          // props.onDismiss(true);
          message.success("Enquiry Submitted");
        } else {
          setSaveLoading(false);
          message.error(response.message);
          // setButtonLoading(false);
        }
      });
    } else {
      console.log(
        "submittedValues = ELSE ",
        convertBasicDetailsToJson(
          {
            ...apiData,
            ...form.getFieldsValue(),
          },
          apiData?.id,
          [],
          apiData?.attachments,
          studentSignature,
          counselorSignature,
          studentImage
        )
      );
    }
  };
  console.log(studentImage, counselorSignature, studentSignature);
  useEffect(() => {
    if (apiData) {
      form.setFieldsValue(convertToAntForm(apiData));
    }
  }, []);

  useEffect(() => {
    setEditOperation({
      ...editOperation,
      onSave: () => {
        handleSubmit(true);
      },
      onCancel: () => {
        setClear(!clear);
        setStudentImage([]);
        setCounselorSignature([]);
        setStudentSignature([]);
        form.setFieldsValue(convertToAntForm(apiData));
      },
    });
  }, [isEdit]);

  useEffect(() => {
    handleSubmit(false);
  }, [studentImage, studentSignature, counselorSignature]);

  useEffect(() => {
    getMaster();
  }, []);
  // console.log("saveLoading in Child = ", saveLoading);

  return (
    <>
      <Spin
        spinning={saveLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          form={form}
          // labelCol={{span:10}}
          layout={
            editOperation?.key === "basicDetails" ? "vertical" : "horizontal"
          }
          className={
            editOperation?.key === "basicDetails" ? "" : "FormlabelColor"
          }
          initialValues={{
            enquiryDate: dayjs(),
            nationality: "Indian",
          }}
          requiredMark={editOperation?.key === "basicDetails" ? true : false}
        >
          {editOperation?.key === "basicDetails" ? (
            <div
              style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
            >
              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                General Information<br></br>
                <span style={{ color: "gray", fontSize: "12px" }}>
                  All the information should be similar to your Documents
                </span>
              </div>
              <Row gutter={16}>
                {/* <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    name="incrementalSeries"
                    label="Enquiry Id"
                  // tooltip="This is a required field"
                  >
                    <Input
                      style={{ width: "100%" }}
                      disabled
                      onInput={(e: any) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value
                            : e.target.value.toUpperCase();
                      }}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col sm={12} lg={6} xs={24}>
                  <Form.Item label="Course" name="course">
                    <Select
                      showSearch
                      disabled
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      labelInValue
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "",
                          label: "",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col> */}

                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 9}
                  xs={24}
                >
                  <Form.Item label="Enquiry Date" rules={[{required:true,message:"Enquiry date cant'be empty"}]} name="enquiryDate">
                    {editOperation?.key === "basicDetails" ? (
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        // disabledDate={(current: any) => {
                        //   return current && current > dayjs().endOf("day");
                        // }}
                      />
                    ) : (
                      <div style={{ paddingTop: "5px" }}>
                        {dayjs(apiData?.enquiryDate)?.format("DD MMM YYYY")}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item label="Enrolled Date " name="enrolledDate">
                    {editOperation?.key === "basicDetails" ? (
                      <DatePicker
                        // disabled
                        style={{width:"245px"}}
                        placeholder="Enrolled date"
                        format={"DD-MM-YYYY"}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {dayjs(apiData?.enrolledDate)?.format("DD-MMM-YYYY")}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="Batch"
                    name="batch"
                    rules={[
                      {
                        required: true,
                        message: "Please select batch!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Batch"
                      // optionFilterProp="children"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      // dropdownRender={(menu) => (
                      //   <>
                      //     {menu}
                      //     <AddInOption
                      //       funcType={"batch"}
                      //       setRefresh={setRefresh}
                      //     />
                      //   </>
                      // )}
                      options={masterData?.batch}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    name="name"
                    label="First Name"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please input First Name!",
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                        message: "Please input alphabets only!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        onInput={(e: any) => {
                          e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase();
                        }}
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.name}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Middle Name" name="middleName">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.middleName}</div>
                    )}
                  </Form.Item>
                </Col>

                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please input Last Name!",
                      // },
                      {
                        pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                        message: "Please input alphabets only!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.lastName}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Date of Birth"
                    name="dateOfBirth"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please input Date of Birth!",
                    //   },
                    // ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        disabledDate={(current: any) => {
                          return current && current > dayjs().endOf("day");
                        }}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {dayjs(apiData?.dateOfBirth)?.format("DD MMM YYYY")}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col sm={12} lg={4} xs={24}>
              <Form.Item label="Blood Group"name="bloodGroup" >
                <Input
                  style={{ width: "100%" }}
                  />
              </Form.Item>
                  </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select Gender!",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Gender"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={masterData?.gender}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.gender?.name}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Nationality" name="nationality">
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        // labelInValue
                        placeholder="Nationality"
                        options={nationalityOption}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.nationality}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="English Proficiency"
                    name="englishProficiency"
                    rules={[
                      {
                        required: true,
                        message: "Please select Languages!",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Languages: English Proficiency"
                        optionFilterProp="children"
                        labelInValue
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={[
                          {
                            value: 0,
                            label: "Beginner",
                          },
                          {
                            value: 1,
                            label: "Intermediate",
                          },
                          {
                            value: 2,
                            label: "Advanced",
                          },
                        ]}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {languageProficiency[apiData?.englishProficiency]}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Status" name="response">
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      popupMatchSelectWidth={true}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      options={[
                        {
                          value: 1,
                          label: "Interested",
                        },
                        {
                          value: 2,
                          label: "Not Interested",
                        },
                        {
                          value: 3,
                          label: "DNP",
                        },
                        {
                          value: 4,
                          label: "Follow Up",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item label="Sources" name="sources">
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Where you got the sources"
                        optionFilterProp="children"
                        labelInValue
                        // filterOption={(input, option) =>
                        //     (option?.label?.toString() ?? "")
                        //         .toLowerCase()
                        //         .includes(input.toLowerCase())
                        // }
                        // options={masterData.source}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.sources?.name}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                {/* <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Assign To" name="assignTo">
                    <Select
                      showSearch
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Choose employee"
                      optionFilterProp="children"
                      labelInValue
                    // filterOption={(input, option) =>
                    //     (option?.label?.toString() ?? "")
                    //         .toLowerCase()
                    //         .includes(input.toLowerCase())
                    // }
                    // options={masterData.assignTo}
                    />
                  </Form.Item>
                </Col> */}

                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Center Visited" name="centerVisited">
                    <Select
                      showSearch
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Center Visited"
                      optionFilterProp="children"
                      popupMatchSelectWidth={true}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: 1,
                          label: "Yes",
                        },
                        {
                          value: 2,
                          label: "No",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 8 : 5}
                  xs={24}
                >
                  <Form.Item
                    label="Counselors"
                    name="counselors"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Counselors"
                        mode="multiple"
                        maxTagCount="responsive"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        // filterOption={(input, option) =>
                        //     (option?.label?.toString() ?? "")
                        //         .toLowerCase()
                        //         .includes(input.toLowerCase())
                        // }
                        // dropdownRender={(menu) => (
                        //   <>
                        //     <Card size="small" title={'Select Primary Counselor'}>

                        //     </Card>

                        //     {menu}
                        //     111
                        //   </>
                        // )}
                        // options={masterData?.counselor}
                        options={counselors}
                        // options={counselors?.map((counselor: any) => ({
                        //   value: counselor?.value,
                        //   label: (
                        //     <Radio>
                        //       {counselor?.label}
                        //     </Radio>
                        //   )
                        // }))}
                        // optionRender={(option: any, info: any) => (
                        //   <Space>
                        //     <Checkbox>
                        //     </Checkbox>
                        //     <Divider type="vertical" />
                        //     <Space>
                        //       {option?.label}
                        //     </Space>
                        //   </Space>
                        // )}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.counselorName?.name}
                      </div>
                    )}
                    {/* <Input
                          placeholder="Counselor Name"
                          style={{ width: "100%" }}
                        /> */}
                  </Form.Item>
                  </Col>
                  <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 8 : 5}
                  xs={24}
                >
                  <Form.Item
                  style={{width:220}}
                    rules={[
                      // {
                      //   type: "email",
                      //   message: "Please input valid email!",
                      //   required: true,
                      // },
                    ]}
                    name={"mentorName"}
                    label="Mentor name"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Mentor name" />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.mentorName}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                  
              </Row>

              <Row gutter={[24, 10]}>
                <Col span={8}>
                  <Form.Item
                    name="otherInformation"
                    label="Other Information"
                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input.TextArea
                        placeholder="Other"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.otherInformation}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="referralSource"
                    label="Referral Source / Where did you hear about us?"
                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input.TextArea
                        placeholder="Referral Source / Where did you hear about us?"
                        autoSize={{ minRows: 3, maxRows: 3 }}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.referralSource}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Address and Contact
              </div>

              <Row gutter={10}>
                <Col lg={4} md={8} sm={8} xs={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input Mobile No.!",
                      },
                      {
                        pattern: new RegExp(/^\d{10}$/),
                        message: "Invalid number",
                      },
                    ]}
                    name={["contactDetail", "mobileNumber"]}
                    label="Contact Number"

                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <InputNumber
                        style={{ width: "100%" }}
                        maxLength={10}
                        minLength={10}
                        controls={false}
                        name="mobileNumber"
                        placeholder="+91 0000 0000"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.contactDetail?.mobileNumber}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col lg={4} md={8} sm={8} xs={24}>
                  <Form.Item
                    name={["contactDetail", "emergencyNumber"]}
                    label="Emergency Number"

                  // tooltip="This is a required field"
                  >
                    <InputNumber
                    
                      style={{ width: "100%" }}
                      maxLength={10}
                      minLength={10}
                      controls={false}
                      placeholder="+91 0000 0000"
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    />
                  </Form.Item>
                </Col>
                <Col lg={4} md={8} sm={8} xs={24}>
                  <Form.Item
                    rules={[
                      {
                        type: "email",
                        message: "Please input valid email!",
                        required: true,
                      },
                    ]}
                    name={["contactDetail", "email"]}
                    label="Email"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Email Address" />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.contactDetail?.email}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col lg={4} md={8} sm={8} xs={24}>
                  <Form.Item
                    rules={[
                      // {
                      //   type: "email",
                      //   message: "Please input valid email!",
                      //   required: true,
                      // },
                    ]}
                    name={"officialEmail"}
                    label="Official Email"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Official Email Address" />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.officialEmail}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
              <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Permanent Address"
                    name={["contactDetail", "permanentAddress"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input Address!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        placeholder="House no, street name, landmark"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.contactDetail?.currentAddress}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              <Col sm={12} lg={4} xs={24}>
              <Form.Item
                label="Current Address"
                name={["contactDetail", "currentAddress"]}
                rules={[
                  {
                    required: true,
                    message: "Please input Address!",
                  },
                  {
                    whitespace: true,
                    message: "Invalid name",
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="House no, street name, landmark"
                  onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                  }
                />
              </Form.Item>
            </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Zip Code"
                    name="zipcode"
                    rules={[
                      {
                        required: true,
                        message: "Please input PinCode!",
                      },
                      {
                        pattern: /^\d{4,6}\b/g,
                        message: "Please input a valid PinCode",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        maxLength={6}
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.zipcode}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please input City Name!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.city}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="State" name="state">
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        // labelInValue
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={IndianState}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {
                          IndianState.find(
                            (x: any) => x.value === apiData?.state
                          )?.label
                        }
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                  <Col sm={12} lg={4} xs={24}/>
                  <Col style={{marginBottom:"20px"}}>
                    <Checkbox
                      onChange={(e) => {
                        const isChecked = e?.target?.checked;
                        const permanentAddress = form.getFieldValue([
                          "contactDetail",
                          "permanentAddress",
                        ]);
                        const currentAddress = form.getFieldValue([
                          "contactDetail",
                          "currentAddress",
                        ]);
                        setCurrentprevAddress(currentAddress);
                        if (isChecked) {
                          form.setFieldValue(
                            ["contactDetail", "currentAddress"],
                            permanentAddress
                          );
                        } else {
                          form.setFieldValue(
                            ["contactDetail", "currentAddress"],
                            currentprevAddress
                          );
                        }
                      }}
                    >
                      <span className="themeColor fw-500">Same as Permanent Address</span>
                    </Checkbox>
                    </Col>
                  </Row>
              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  marginTop: "12px",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Parents and Guardian
              </div>
              <Row gutter={16}>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Father Name" name="fatherName">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Father Name"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.fatherName}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Father Occupation" name="fatherOccupation">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Father Occupation"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.fatherOccupation}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item label="Mother Name" name="motherName">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Mother Name"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.motherName}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item label="Mother Occupation" name="motherOccupation">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Mother Occupation"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.motherOccupation}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Guardian Name"
                    name="guardianName"
                    rules={[
                      {
                        required: true,
                        message: "Please input Guardian Name!",
                      },
                      {
                        pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                        message: "Please input alphabets only!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Guardian Name"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.name}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Guardian Relation" name="guardianRelation">
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Guardian Relation"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                        onKeyPress={(e) =>
                          !/[a-z]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianRelation}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please input Mobile No.!",
                      },
                      {
                        pattern: new RegExp(/^\d{10}$/),
                        message: "Invalid number",
                      },
                    ]}
                    name="guardianPhone"
                    label="Guardian Phone No."

                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <InputNumber
                        style={{ width: "100%" }}
                        maxLength={10}
                        minLength={10}
                        controls={false}
                        name="mobileNumber"
                        placeholder="+91 0000 0000"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianPhone}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Guardian Occupation"
                    name="guardianOccupation"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        placeholder="Guardian Occupation"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianOccupation}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="sameAsCandidateAddress" valuePropName="checked">
                <Checkbox
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      form.setFieldValue(
                        "guardianAddress",
                        form.getFieldValue(["contactDetail", "currentAddress"])
                      );
                      form.setFieldValue(
                        "guardianZipCode",
                        form.getFieldValue("zipcode")
                      );
                      form.setFieldValue(
                        "guardianCity",
                        form.getFieldValue("city")
                      );
                      form.setFieldValue(
                        "guardianState",
                        form.getFieldValue("state")
                      );
                    } else {
                      form.setFieldValue(
                        "guardianAddress",
                        form.getFieldValue("")
                      );
                      form.setFieldValue(
                        "guardianZipCode",
                        form.getFieldValue("")
                      );
                      form.setFieldValue(
                        "guardianCity",
                        form.getFieldValue("")
                      );
                      form.setFieldValue(
                        "guardianState",
                        form.getFieldValue("")
                      );
                    }
                  }}
                  checked={apiData?.sameAsCandidateAddress}
                  disabled={(editOperation?.key === "basicDetails") === false}
                >
                  <span className="themeColor fw-500">
                    Same as Candidate Address
                  </span>
                </Checkbox>
              </Form.Item>
              <Row gutter={16}>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Guardian Address"
                    name="guardianAddress"
                    rules={[
                      {
                        required: true,
                        message: "Please input Address!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        placeholder="House no, street name, landmark"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianAddress}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Zip Code"
                    name="guardianZipCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input PinCode!",
                      },
                      {
                        pattern: /^\d{4,6}\b/g,
                        message: "Please input a valid PinCode",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        maxLength={6}
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianZipCode}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 4 : 5}
                  xs={24}
                >
                  <Form.Item
                    label="City"
                    name="guardianCity"
                    rules={[
                      {
                        required: true,
                        message: "Please input City Name!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.guardianCity}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item label="Guardian State" name="guardianState">
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={IndianState}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {
                          IndianState.find(
                            (state: any) => state.value === apiData?.state
                          )?.label
                        }
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Education<br></br>
                <span style={{ color: "gray", fontSize: "12px" }}>
                  To enroll in B.Sc. Required 12th grade clear
                </span>
              </div>

              <Row gutter={16}>
                <Col sm={12} lg={5} xs={24}>
                  <Form.Item
                    label="Current / Previous Grades"
                    name="currentClass"
                    rules={[
                      {
                        required: true,
                        message: "Please input Grade!",
                      },
                      {
                        whitespace: true,
                        message: "Invalid name",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        style={{ width: "100%" }}
                        placeholder="12th"
                        onInput={(e: any) =>
                          (e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase())
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.currentClass}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 5 : 9}
                  xs={24}
                >
                  <Form.Item
                    label="Current / Previous School Name"
                    name="currentSchoolName"
                    rules={[
                      {
                        required: true,
                        message: "Please select School Name!",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.currentSchoolName}
                      </div>
                    )}
                    {/* <Select
                      style={{ width: "100%" }}
                      placeholder="Select School Name"
                      optionFilterProp="children"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      showSearch
                      // filterOption={(input, option) =>
                      //   (option?.label?.toString() ?? "")
                      //     .toLowerCase()
                      //     .includes(input.toLowerCase())
                      // }
                      options={[]}
                    /> */}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Medium"
                    name="medium"
                    rules={[
                      {
                        required: true,
                        message: "Please select medium!",
                      },
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Hindi/English"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={masterData?.medium}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.medium?.name}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    name="curriculum"
                    rules={[
                      {
                        required: true,
                        message: "Please select Curriculum!",
                      },
                    ]}
                    label="Curriculum"
                    required
                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Curriculum"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={masterData?.curriculum}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.curriculum?.name}
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    name="currentPassOutYear"
                    rules={[
                      {
                        required: true,
                        message: "Please input Current Passout Year!",
                      },
                      {
                        validator: validateCurrentPassOutYear,
                        // message: "Invalid Year",
                      },
                    ]}
                    label="Current Passout Year"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <InputNumber
                        minLength={4}
                        maxLength={4}
                        style={{ width: "100%" }}
                        controls={false}
                        name="current Passout Year"
                        placeholder="Current  Passout Year"
                        formatter={(value: any) =>
                          value ? `${parseInt(value, 10)}` : ""
                        } // Format the value to remove decimal parts
                        parser={(value) =>
                          value ? `${parseInt(value, 10)}` : ""
                        } // Parse the value to remove decimal parts
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.currentPassOutYear}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col
                  sm={12}
                  lg={editOperation?.key === "basicDetails" ? 5 : 9}
                  xs={24}
                >
                  <Form.Item
                    name="stream"
                    rules={[
                      {
                        required: true,
                        message: "Please select Stream!",
                      },
                    ]}
                    label="Stream"
                    required
                    // tooltip="This is a required field"
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Stream:"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={masterData?.streams}
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.stream?.name}
                      </div>
                    )}
                  </Form.Item>
                </Col>
                <Col sm={12} lg={4} xs={24}>
                  <Form.Item
                    label="Percentage"
                    name="percentage"
                    rules={[
                      {
                        validator: validatePercentage,
                      },
                  
                    ]}
                  >
                    {editOperation?.key === "basicDetails" ? (
                      <Input
                        // maxLength={3}
                        style={{ width: "100%" }}
                        // onInput={(e: any) =>
                        //   (e.target.value =
                        //     e.target.value.length > 1
                        //       ? e.target.value
                        //       : e.target.value.toUpperCase())
                        // }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.percentage}</div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {editOperation?.key === "basicDetails" ? (
                <Form.List name="academicRecord">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <div key={index}>
                          <Row gutter={10} style={{ marginTop: "20px" }}>
                            <Col
                              sm={6}
                              lg={editOperation?.key === "basicDetails" ? 2 : 3}
                            >
                              <Form.Item
                                name={[name, "class"]}
                                {...restField}
                                label={
                                  editOperation?.key === "basicDetails"
                                    ? ""
                                    : "Grade/Graduate:"
                                }
                              >
                                {editOperation?.key === "basicDetails" ? (
                                  <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder="Grade/Graduate"
                                    optionFilterProp="children"
                                    labelInValue
                                    allowClear
                                    popupMatchSelectWidth={true}
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={masterData?.classes}
                                  />
                                ) : (
                                  <div style={{ paddingTop: 5 }}>
                                    {apiData?.academicRecord[name]?.class?.name}
                                  </div>
                                )}
                              </Form.Item>
                            </Col>
                            <Col sm={4} lg={5}>
                              {" "}
                              <Form.Item
                                name={[name, "schoolName"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing class",
                                  },
                                ]}
                                {...restField}
                                label={
                                  editOperation?.key === "basicDetails"
                                    ? ""
                                    : "School/Collage Name"
                                }
                              >
                                {editOperation?.key === "basicDetails" ? (
                                  <Input placeholder="School/Collage Name" />
                                ) : (
                                  <div style={{ paddingTop: 5 }}>
                                    {apiData?.academicRecord[name]?.schoolName}
                                  </div>
                                )}
                                {/* <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="School/Collage Name"
                                optionFilterProp="children"
                                labelInValue
                                allowClear
                                popupMatchSelectWidth={true}
                                filterOption={(input, option) =>
                                  (option?.label?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={masterData?.classes}
                              /> */}
                              </Form.Item>
                            </Col>
                            <Col sm={3} lg={2}>
                              {" "}
                              <Form.Item
                                name={[name, "year"]}
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input year!",
                                  },
                                  {
                                    validator: validateCurrentPassOutYear,
                                    // message: "Invalid Year",
                                  },
                                ]}
                                label={
                                  editOperation?.key === "basicDetails"
                                    ? ""
                                    : "Year"
                                }
                              >
                                {editOperation?.key === "basicDetails" ? (
                                  <InputNumber
                                    minLength={4}
                                    maxLength={4}
                                    style={{ width: "100%" }}
                                    controls={false}
                                    placeholder="Year"
                                    formatter={(value: any) =>
                                      value ? `${parseInt(value, 10)}` : ""
                                    } // Format the value to remove decimal parts
                                    parser={(value) =>
                                      value ? `${parseInt(value, 10)}` : ""
                                    } // Parse the value to remove decimal parts
                                  />
                                ) : (
                                  <div style={{ paddingTop: 5 }}>
                                    {apiData?.academicRecord[name]?.year}
                                  </div>
                                )}
                              </Form.Item>
                            </Col>

                            <Col sm={3} lg={2}>
                              {" "}
                              <Form.Item
                                name={[name, "percentage"]}
                                {...restField}
                                rules={[
                                  // {
                                  //   pattern: new RegExp(/^[0-9]/i),
                                  //   message: "Invalid percent!",
                                  // },
                                  {
                                    validator: validatePercentage,
                                  },
                                  {
                                    required: true,
                                    message: "Percent is Required",
                                  },
                                ]}
                                label={
                                  editOperation?.key === "basicDetails"
                                    ? ""
                                    : "Percent"
                                }
                              >
                                {editOperation?.key === "basicDetails" ? (
                                  <Input
                                    suffix={"%"}
                                    placeholder="Percent"
                                    minLength={2}
                                    maxLength={5}
                                  />
                                ) : (
                                  <div style={{ paddingTop: 5 }}>
                                    {apiData?.academicRecord[name]?.percentage}
                                  </div>
                                )}
                              </Form.Item>
                            </Col>
                            <Col sm={4} lg={2}>
                              {" "}
                              <Form.Item
                                name={[name, "studyMode"]}
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing study mode",
                                  },
                                ]}
                                label={
                                  editOperation?.key === "basicDetails"
                                    ? ""
                                    : "Study Mode"
                                }
                              >
                                {editOperation?.key === "basicDetails" ? (
                                  <Select
                                    placeholder="Study Mode"
                                    labelInValue
                                    allowClear
                                    style={{ width: "100%" }}
                                    options={[
                                      {
                                        value: 0,
                                        label: "Regular",
                                      },
                                      {
                                        value: 1,
                                        label: "Private",
                                      },
                                    ]}
                                  />
                                ) : (
                                  <div style={{ paddingTop: 5 }}>
                                    {
                                      StudyModeObj[
                                        apiData?.academicRecord[name]?.studyMode
                                      ]
                                    }
                                  </div>
                                )}
                              </Form.Item>
                            </Col>
                            <Col lg={3}>
                              <Form.Item
                                name={[name, "curriculum"]}
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Curriculum!",
                                  },
                                ]}
                                required
                                // tooltip="This is a required field"
                              >
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Curriculum"
                                  optionFilterProp="children"
                                  labelInValue
                                  allowClear
                                  popupMatchSelectWidth={true}
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={masterData?.curriculum}
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={4}>
                              <Form.Item
                                name={[name, "stream"]}
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Stream!",
                                  },
                                ]}
                                required
                                // tooltip="This is a required field"
                              >
                                <Select
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Stream:"
                                  optionFilterProp="children"
                                  labelInValue
                                  allowClear
                                  popupMatchSelectWidth={true}
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  options={masterData?.streams}
                                />
                              </Form.Item>
                            </Col>
                            <Col sm={1}>
                              {/* {index > 0 ? ( */}
                              <Button
                                onClick={() => {
                                  // if (index !== 0)
                                  remove(index);
                                }}
                                icon={<DeleteOutlined />}
                              />
                              {/* ) : null} */}
                            </Col>
                          </Row>
                        </div>
                      ))}

                      {editOperation?.key === "basicDetails" ? (
                        <Form.Item className="inputBoxMb">
                          <Row key={"add contact"}>
                            <Col>
                              <Button
                                type="primary"
                                ghost
                                size="middle"
                                onClick={() => add()}
                                // block
                                icon={<PlusOutlined />}
                                disabled={fields?.length < 5 ? false : true}
                              >
                                Add Education
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      ) : null}
                    </>
                  )}
                </Form.List>
              ) : (
                <>
                  <Row gutter={[24, 16]}>
                    <Col span={4} style={{ color: "gray" }}>
                      School/Collage Name
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Grade/Graduate
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Year
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Percent
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Study Mode
                    </Col>
                    <Col span={4} style={{ color: "gray" }}>
                      Curriculum
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      stream
                    </Col>
                  </Row>

                  {apiData?.academicRecord?.map((itm: any, indx: number) => (
                    <Row key={indx} gutter={[24, 16]}>
                      <Col span={4}>{itm?.schoolName}</Col>
                      <Col span={3}>{itm?.class?.name}</Col>
                      <Col span={3}>{itm?.year}</Col>
                      <Col span={3}>{itm?.percentage}</Col>
                      <Col span={3}>{StudyModeObj[itm?.studyMode]}</Col>
                      <Col span={4}>{itm?.curriculum?.name}</Col>
                      <Col span={3}>{itm?.stream?.name}</Col>
                    </Row>
                  ))}
                </>
              )}

              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  fontSize: 15,
                  fontWeight: 600,
                  marginTop: editOperation?.key === "basicDetails" ? 0 : 18,
                }}
              >
                Upload Documents<br></br>
                <span style={{ color: "gray", fontSize: "12px" }}>
                  Please upload all the necessary document to enroll
                </span>
              </div>
              <Row gutter={[24, 16]}>
                <Col sm={5}>
                  <Form.Item label="Photo">
                    <ImageUploader
                      defaultImages={[]}
                      setFileUrls={setStudentImage}
                      limit={1}
                      shouldPreview={true}
                      children={undefined}
                      shouldCrop={false}
                      clearImage={clear}
                      readonly={true}
                      source={form.getFieldValue("candidateImage")?.path}
                    />
                  </Form.Item>
                </Col>
                <Col sm={5}>
                  <Form.Item label="Signature">
                    <ImageUploader
                      defaultImages={[]}
                      setFileUrls={setStudentSignature}
                      limit={1}
                      shouldPreview={true}
                      children={undefined}
                      shouldCrop={false}
                      clearImage={clear}
                      readonly={true}
                      source={form.getFieldValue("signature")?.candidate?.path}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Counselor Signature"
                    rules={[
                      {
                        required: true,
                        message: "Please select Signature!",
                      },
                    ]}
                  >
                    <ImageUploader
                      defaultImages={[]}
                      setFileUrls={setCounselorSignature}
                      limit={1}
                      shouldPreview={true}
                      children={undefined}
                      shouldCrop={false}
                      clearImage={clear}
                      readonly={true}
                      source={form.getFieldValue("signature")?.counselor?.path}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  color: "#4c4c4c",
                  marginBottom: "10px",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Required Information<br></br>
              </div>
              <Row gutter={[10, 16]}>
                <Col md={4}>
                  <Form.Item label="Total Fees" name="amount">
                    {editOperation?.key === "basicDetails" ? (
                      <InputNumber
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                        controls={false}
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.amount}</div>
                    )}
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item label="Student ID" name="studentId">
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Student ID" disabled />
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.studentId}</div>
                    )}
                    {/* <label>
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                  </Form.Item>
                </Col>
                <Col md={4}>
                  <Form.Item label="Card No." name="cardNo">
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Card No." disabled/>
                    ) : (
                      <div style={{ paddingTop: 5 }}>{apiData?.cardNo}</div>
                    )}
                    {/* <label>
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                  </Form.Item>
                </Col>

                <Col md={4}>
                  <Form.Item label="Biometric Code" name="biometricCode">
                    {editOperation?.key === "basicDetails" ? (
                      <Input placeholder="Biometric Code" disabled />
                    ) : (
                      <div style={{ paddingTop: 5 }}>
                        {apiData?.biometricCode}
                      </div>
                    )}
                    {/* <label>) 
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ) : (
            <ViewStudentDetails apiData={apiData} />
          )}
        </Form>
      </Spin>
    </>
  );
};

export default BasicStudentDetails;
