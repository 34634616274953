import { Button, Col, Row, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import dayjs from "dayjs";
import { leaveStatusEnum } from "../../Constant/Enums";
import { pageSizeOption } from "../LMS/SubjectAlllocation/SubjectAllocation";
import AttendanceReqModal from "./AttendanceReqModal";

const AttendanceRequest = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [open, setOpen] = useState<boolean>(false);
  const [tableRecord, setTableRecord] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const[loading,setLoading]=useState(false);
  const getAttendanceRequest = async () => {
     setLoading(true);
    try {
      const response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_ATTENDANCE_REQUEST + "?",
        {
            page:pagingItems.currentPage,
            pageSize:pagingItems.pageSize
        }
      );
      if (response?.result?.items) {
        console.log(response?.result?.items?.totalRecords, "AppData");
        setDataSource(response?.result?.items);
        setPagingItems((prev)=>({
            ...prev,
            totalRecords:response?.result?.totalRecords
        }))
      }
    } catch (error) {
      console.error("Failed to fetch request", error);
    }
    finally{
      setLoading(false)
    }
  };
  useEffect(() => {
    getAttendanceRequest();
  }, [refresh,pagingItems.currentPage,pagingItems.pageSize]);
  
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  const correctionColumn: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "0.7%",
      key: "sno",
      render: (text: any, record: any, index: any) => (
        <>
          <p>{(pagingItems.currentPage - 1) * pagingItems.pageSize + index + 1}</p>
        </>
      ),
    },
    {
      title: "Student",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text, record) => <span>{record?.student?.name}</span>,
      width: "2%",
      ellipsis: true,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      render: (text, record) => <span>{record?.batch?.name}</span>,
      width: "2%",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date, record) => (
        <span>{dayjs(record?.createdBy?.date).format("DD/MM/YYYY")}</span>
      ),
      width: "2%",
    },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis:true,
      //   render: (text: string, record: any) => {
      //     const wordLimit = 15;
      //     const reasontext = record.reason;
      //     const isExceedingLimit = reasontext.length > wordLimit;
      //     return isExceedingLimit ? (
      //       <Tooltip title={reasontext}>
      //         <span>{reasontext.slice(0, wordLimit) + "..."}</span>
      //       </Tooltip>
      //     ) : (
      //       <span>{reasontext}</span>
      //     );
      //   },
      width: "2%",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      ellipsis: true,
      render: (text, record) => <span>{text || "-" }</span>,
      width: "2%",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          const status: leaveStatusEnum = text;
          let color = "";
          switch (status) {
            case leaveStatusEnum.Approve:
              color = "#87d068";
              break;
            case leaveStatusEnum.Pending:
              color = "#4F93A9";
              break;
            case leaveStatusEnum.Cancel:
              color = "#FFA500";
              break;
            case leaveStatusEnum.Reject:
              color = "#f50";
              break;
            default:
              color = "gray";
          }
  
          return (
            <Tag style={{ width: "55%", textAlign: "center" }} color={color}>
              {leaveStatusEnum[status]}
            </Tag>
          );
        },
        width: "1.5%",
      },
    // {
    //   title: (
    //     <>
    //       <THeader
    //         title={"Status"}
    //         setShouldRefresh={setRefresh}
    //         selectedValue={selectedStatus}
    //         setSelectedValue={setSelectedStatus}
    //         option={[
    //           { label: "Approve", value: leaveStatusEnum.Approve },
    //           { label: "Pending", value: leaveStatusEnum.Pending },
    //           { label: "Cancel", value: leaveStatusEnum.Cancel },
    //           { label: "Reject", value: leaveStatusEnum.Reject },
    //         ]}
    //         changeListParams={(value: any) => setSelectedStatus(value)} // Update selectedStatus
    //       />
    //     </>
    //   ),
    //   dataIndex: "status",
    //   key: "status",
    //   render: (text, record) => {
    //     const status: leaveStatusEnum = text;
    //     let color = "";
    //     switch (status) {
    //       case leaveStatusEnum.Approve:
    //         color = "#87d068";
    //         break;
    //       case leaveStatusEnum.Pending:
    //         color = "#4F93A9";
    //         break;
    //       case leaveStatusEnum.Cancel:
    //         color = "#FFA500";
    //         break;
    //       case leaveStatusEnum.Reject:
    //         color = "#f50";
    //         break;
    //       default:
    //         color = "gray";
    //     }

    //     return (
    //       <Tag style={{ width: "70%", textAlign: "center" }} color={color}>
    //         {leaveStatusEnum[status]}
    //       </Tag>
    //     );
    //   },
    //   width: "1.5%",
    // },
    {
      title: "Action",
      key: "action",
      width: "1.5%",
      render: (text, record) => (
        <>
          <Button
            size="small"
            onClick={() => {
              showModal();
              setTableRecord(record);
            }}
          >
            Action
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24} style={{ paddingTop: "10px" }}>
          <Table
            // className="cit-table"
            loading={loading}
            size="small"
            columns={correctionColumn}
            dataSource={dataSource}
            scroll={{y:600}}

            pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: pageSizeOption,
              }}
              onChange={(paging, filter, sort: any) => {
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
          >
          </Table>
        </Col>
      </Row>
      {open && (
        <AttendanceReqModal
          open={open}
          handleCancel={handleCancel}
          tableRecord={tableRecord}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
};

export default AttendanceRequest;
