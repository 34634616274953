import * as React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Upload,
  Radio,
  Image,
  message,
  Table,
  Space,
  TimePicker,
  DatePicker,
  Drawer,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import type { GetProp, UploadFile, UploadProps } from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import type { SelectProps } from "antd";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { GradingScaleOptions, MasterTypes } from "../../../../Constant/Enums";
import { adminContext } from "../../../../Common/PageRoute";
import dayjs from "dayjs";

interface I_Props {
  onClose: Function;
  // form: any;
  // handelFile: any;
  // handlePreview: any;
  // previewImage: any;
  // previewOpen: any;
  // setPreviewImage: any;
  // setPreviewOpen: any;
  assessmentsId: any;
  // currectPath:any;
  open: any;
}

const AddAssessments: React.FC<I_Props> = (props) => {
  const [marksPoint, setMarksPoint] = React.useState<any>("");
  const [passValidation, setPassValidation] = React.useState<
    number | undefined
  >();
  const [loading, setLoading] = React.useState<any>(false);
  const [departMentData, setDepartMentData] = React.useState<any>([]);
  const [courseData, setCourseData] = React.useState<any>([]);
  const [batchData, setBatchData] = React.useState<any>([]);
  const [semesterData, setSemesterData] = React.useState<any>([]);
  const [sectionsData, setSectionsData] = React.useState<any>([]);
  const [departmentId, setDepartmentId] = React.useState<any>([]);
  const [subjectsData, setSubjectsData] = React.useState<any>([]);
  const [buttonLoading,setButtonLoading]= React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<number>(0);
  const [form] = Form.useForm();
  const { RangePicker } = TimePicker;
  React.useEffect(() => {
    setPassValidation(form.getFieldValue("totalMarks"));
  }, []);
  const { openNotification } = React.useContext(adminContext);
  React.useEffect(() => {
    let temp = form.getFieldValue("marksIn") || "";
    setMarksPoint(temp);
  }, [marksPoint]);

  const { data: AssessmentCategory } = useQuery({
    queryKey: ["assessmentCategory"],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: 0,
            length: 50,
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          // console.log(res?.result?.items, "categoryOption");
          return res?.result?.items.map((item: any) => ({
            key: item.id,
            label: item.name,
            value: item.id,
          }));
        } else {
          console.log("error");
          return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  });

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  // const handleChange = (value: string[]) => {
  //   console.log(`selected ${value}`);
  // };

  // const upload: UploadProps = {
  //   name: "file",
  //   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };
  // const getBase64 = (file: FileType): Promise<string> =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  // const normFile = (e: any) => {
  //   console.log("Upload event:", e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e?.fileList;
  // };

  const handleSubmit = () => {
    form.validateFields().then(async (val: any) => {
      setButtonLoading(true); 
      try {
        const res = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.POST_ASSESSMENTS_DATA,
          {},
          {
            ...(props?.assessmentsId && { id: props?.assessmentsId }),
            category: {
              id: val.category.value,
              name: val.category.label,
            },
            assessmentName: val.assessmentName,
            isCountOverallScore: val?.isCountOverallScore,
            passMarks: val.passMarks,
            department: val.department,
            course: val.course,
            batch: {
              id: val?.batch?.value,
              name: val?.batch?.label,
            },
            semester: val.semester,
            section: val.seciton,
            assessmentDetails: val?.assessmentDetails?.map((detail: any) => ({
              subject: {
                id: detail?.subject?.id,
                name: detail?.subject?.name,
              },
              maxMarks: val?.maxMarks,
              passMarks: val?.passMarks,
              date: dayjs(detail?.date).format('YYYY-MM-DD'),
              time: {
                from: dayjs(detail?.from).format('HH:mm'),
                to: dayjs(detail?.to).format('HH:mm'),
              },
            })),
          }
        );
        message.success("Assessment created successfully")
        // console.log("Response:", res);
        setButtonLoading(false); 
        props.onClose();
        form.resetFields(); 
      } catch (error) {
        console.error("Error submitting the form:", error);
        setButtonLoading(false);
      }
    });
  };
  
  const listColumns = [
    {
      title: <div style={{ textAlign: "center" }}>Subject</div>,
      dataIndex: "subjects",
      width: "4%",
      key: "subjects",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "subjects"]}
          rules={[
            {
              required: true,
              message: "Missing first name",
            },
          ]}
        >
          <Select
            style={{ width: "150px" }}
            placeholder="Select subject"
            options={subjectsData}
          />
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Max. Marks</div>,
      dataIndex: "maxMarks",
      width: "10%",
      key: "maxMarks",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "maxMarks"]}
          rules={[
            {
              required: true,
              message: "Missing last name",
            },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Pass. Marks</div>,
      width: "10%",
      dataIndex: "passMarks",
      // width:"40%",
      key: "passMarks",
      render: (_: any, record: any, index: number) => (
        <>
          <Form.Item
            name={[index, "passMarks"]}
            style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
            rules={[
              {
                required: true,
                message: "Missing last name",
              },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Date</div>,
      width: "15%",
      dataIndex: "date",
      key: "date",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "date"]}
          rules={[
            {
              required: true,
              message: "Missing last name",
            },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      ),
    },
    {
      title: <div style={{ textAlign: "center" }}>Time</div>,
      dataIndex: "time",
      width: "15%",
      key: "time",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
          name={[index, "time"]}
          rules={[
            {
              required: true,
              message: "Missing last name",
            },
          ]}
        >
          {/* <TimePicker style={{ width: "90%" }} /> */}
          <RangePicker
            format={"HH:mm"}
            placeholder={["from", "to"]}
          />
        </Form.Item>
      ),
    },
    // {
    //   title: "Teacher",
    //   dataIndex: "last",
    //   width: "15%",
    //   key: "last",
    //   render: (_: any, __: any, index: number) => (
    //     <Form.Item
    //       style={{ marginBottom: "-2px", padding: "5px 0px 5px 0px" }}
    //       name={[index, "last"]}
    //       rules={[
    //         {
    //           required: true,
    //           message: "Missing last name",
    //         },
    //       ]}
    //     >
    //       <Select style={{ width: "100%" }} placeholder="Teacher" />
    //     </Form.Item>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "",
      width: "2%",
      key: "",
      render: (_: any, __: any, index: number) => (
        <Form.Item
          style={{ marginBottom: "-1px", padding: "5px 0px 5px 0px" }}
          // name={[index, "last"]}
        >
          <DeleteOutlined
            className="cursor"
            onClick={(index: any) => removeField(index)}
          />
        </Form.Item>
      ),
    },
  ];
  const addField = () => {
    const assessmentDetails = form.getFieldValue("assessmentDetails") || [];
    form.setFieldsValue({
      assessmentDetails: [
        ...assessmentDetails,
        { subjects: "", maxMarks: "", passingMarks: "", date: "", time: "" },
      ],
    });
  };

  const removeField = (index: number) => {
    const assessmentDetails = form.getFieldValue("assessmentDetails") || [];
    assessmentDetails.splice(index, 1);
    form.setFieldsValue({
      assessmentDetails,
    });
  };

  const getDesignCourseData = async (formValues: any) => {
    try {
      setLoading(true);
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_LIST + "?",
        {},
        {
          departMentId: formValues?.department?.value || "",
          courseId: formValues?.course?.value || "",
          batchId: formValues?.batch?.value || "",
          semesterId: formValues?.semesters?.value || "",
          sectionId: formValues?.section?.value || "",
        }
      );
      if (res?.result) {
        // console.log(res?.result,"resresult")
        let departmentData = res?.result?.departments?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setDepartMentData(departmentData);
        // console.log(departmentData?.value, "departmentdata");
        let courseData = res?.result?.courses?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setCourseData(courseData);

        let batchData = res?.result?.batches?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setBatchData(batchData);
        let semesterData = res?.result?.semesters?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSemesterData(semesterData);
        let sectionsData = res?.result?.sections?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSectionsData(sectionsData);
        let subjectsData = res?.result?.subjects?.map((itm: any) => {
          return {
            value: itm?.id,
            label: itm?.name,
          };
        });
        setSubjectsData(subjectsData);
      }
    } catch (error) {
      console.error("something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const formValues = form.getFieldsValue();
    getDesignCourseData(formValues);
  }, [trigger]);

  return (
    <div>
      <Drawer
        
        title="Add Assessment"
        open={props.open}
        onClose={() => props.onClose()}
        destroyOnClose
        width={980}
        size="large"   
        footer={
          <Row>
            <Button
              loading={buttonLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              onClick={handleSubmit}
            >
              {props.assessmentsId ? "Update" : "Save"}
            </Button>
            <Button onClick={() => props.onClose()}>Cancel</Button>
          </Row>
        }
      >
        <Row style={{ marginLeft: 20, marginTop: 20 }}>
          <Col span={23}>
            {/* <Form form={form} layout="vertical"> */}
            <Form form={form}>
              <div
                style={
                  {
                    // maxHeight: `calc(100vh - 115px)` ,
                    // overflowY: "auto",
                  }
                }
              >
                <Row gutter={[20, 0]}>
                  <Col sm={12} lg={12} xs={24}>
                    <Form.Item
                      label="Category"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Please select Category!",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Choose Category"
                        optionFilterProp="children"
                        labelInValue
                        allowClear
                        popupMatchSelectWidth={true}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={AssessmentCategory}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} lg={12} xs={24}>
                    <Form.Item
                      name="assessmentName"
                      label="Assessment Name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input Assessments Name!",
                      //   },
                      // {
                      //   whitespace: true,
                      //   message: "Invalid name",
                      // },
                      // ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Name"
                        onInput={(e: any) => {
                          e.target.value =
                            e.target.value.length > 1
                              ? e.target.value
                              : e.target.value.toUpperCase();
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row>
                <Form.Item
                  name={"marksIn"}
                  label="Marks in :"
                  rules={[
                    { required: true, message: "Please input Maks Group!" },
                  ]}
                > */}
                {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Marks in:
                </Typography.Text> */}
                {/* <Radio.Group
                    name="marksGroup"
                    onChange={(e)=>{
                      setMarksPoint(e.target.value)
                    }}
                    style={{ marginTop: 5, marginLeft: 10 }}
                  >
                    <Radio value={"points"}>Points</Radio>
                    <Radio value={"marks"}>Marks</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>

              <Row gutter={[20, 0]}>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    name="totalMarks"
                    label= {`Total ${marksPoint}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input Total Marks!",
                      },
                      // {
                      //   validator(rule: any, val: any, callback) {
                      //     if (val > 100) {
                      //       callback("less than and equal to 100")
                      //     }
                      //     else{
                      //       callback()
                      //     }
                      //   }
                      // }
                    ]}
                  >
                    <Input placeholder="400" style={{ width: "100%" }} 
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                    onChange={(element)=>{
                      setPassValidation(Number(element.target.value))
                    }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} lg={12} xs={24}>
                  <Form.Item
                    name="passingMarks"
                    label={`Passing ${marksPoint}`}
                    rules={[
                      {
                        required: true,
                        message: "Please input Assessments Name!",
                      },
                      // {
                      //   whitespace: true,
                      //   message: "Invalid name",
                      // },
                      {
                        validator(rule: any, val: any, callback) {
                          if ( passValidation !==undefined && val > passValidation) {
                            callback(`passing ${marksPoint} should be less than Total ${marksPoint}`)
                          }
                          else{
                            callback()
                          }
                        }
                      }
                    ]}
                  >
                    <Input
                      placeholder="200"
                      // style={{ width: "88%", marginLeft: 24 }}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                      onChange={()=>{
                        console.log(passValidation,"nn");
                        // setPassValidation(form.getFieldValue("totalMarks"))
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

                <Row>
                  <Form.Item
                    name={"isCountOverallScore"}
                    label="Counts towards Overall Score : "
                    rules={[
                      {
                        required: true,
                        message: "Please Select Field!",
                      },
                    ]}
                  >
                    <Radio.Group name="ScoreGroup" style={{ marginTop: 5 }}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Counts towards Overall Score:
                </Typography.Text> */}
                </Row>

                {/* <Row>
                <Col sm={12} lg={24} xs={24}>
                  <Form.Item
                    label="Grading Scale"
                    name="gradingScale"
                    rules={[
                      {
                        required: true,
                        message: "Please select Grade!",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Grades/ Rubric/ Percentages/ Actual Points or Marks"
                      optionFilterProp="children"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={GradingScaleOptions}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

                {/* <Row>
                <Col span={24}>
                  <Form.Item
                    label="Applied To"
                    name={"appliedTo"}
                    rules={[
                      {
                        required: true,
                        message: "Please select Applied To!",
                      },
                    ]}
                  >
                    <Radio.Group name="applyGroup" style={{ marginTop: 5 }}>
                      <Radio value={"all"}>All</Radio>
                      <Radio value={"particularSession"}>
                        Particular Session
                      </Radio>
                      <Radio value={"particularCourseSem"}>
                        Particular Course/semester
                      </Radio>
                      <Radio value={"particularSubject"}>
                        Particular Subject
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row> */}

                {/* <Row>
                <Col span={24}>
                  <Form.Item label="Select Course/semester"
                  name={"semesterVal"}
                  rules={[{
                    required: true,
                    message: "Please select Course/semester!",
                  }]}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      onChange={handleChange}
                      options={options}
                    />
                  </Form.Item>
                </Col>
              </Row> */}
                {/* 
              <Row>
                <Form.Item
                  name={"onlineSubmission"}
                  label="Online submission:"
                  rules={[
                    {
                      required: true,
                      message: "Please select Online submission!",
                    },
                  ]}
                > */}
                {/* <Typography.Text
                  style={{ marginRight: 20, marginBottom: 24, fontSize: 15 }}
                >
                  Online submission:
                </Typography.Text> */}
                {/* <Radio.Group name="submissionGroup" style={{ marginTop: 5 }}>
                    <Radio value={"yes"}>Yes</Radio>
                    <Radio value={"no"}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row> */}
                {/* 
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter description!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      // placeholder="Other"
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>
              </Row> */}

                {/* <Row>
                <Upload {...upload}>
                  <Button icon={<UploadOutlined />}>Add Attachment</Button>
                </Upload>
              </Row> */}

                {/* <Row>
                <Form.Item
                  name="attachments"
                  label="Upload"
                  
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload
                    name="logo"
                    action="/upload.do"
                    listType="picture"
                    onPreview={props.handlePreview}
                    beforeUpload={(file: any) => {
                      props.handelFile(file);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Add Attachment</Button>
                  </Upload>
                </Form.Item>
              {  console.log(props.previewImage)}
                
                {props.previewImage && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: props.previewOpen,
                      onVisibleChange: (visible) => props.setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && props.setPreviewImage(""),
                    }}
                    src={props.previewImage}
                  />
                )}
              </Row> */}

                <Row>
                  <Col span={24}>
                    <Row gutter={18}>
                      <Col span={9}>
                        <Form.Item
                          name={"department"}
                          rules={[
                            { required: true, message: "select department" },
                          ]}
                          label="Department"
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelInValue={true}
                            options={departMentData}
                            onChange={(e: any) =>
                              setTrigger((prev) => prev + 1)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={"course"}
                          rules={[{ required: true, message: "select course" }]}
                          label="Course"
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelInValue={true}
                            options={courseData}
                            onChange={(e: any) =>
                              setTrigger((prev) => prev + 1)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item
                          name={"batch"}
                          rules={[{ required: true, message: "select batch" }]}
                          label="Batch"
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelInValue={true}
                            options={batchData}
                            onChange={(e: any) =>
                              setTrigger((prev) => prev + 1)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={18}>
                      <Col span={8}>
                        <Form.Item
                          name={"semesters"}
                          rules={[
                            { required: true, message: "select semester" },
                          ]}
                          label="Semesters"
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelInValue={true}
                            options={semesterData}
                            onChange={(e: any) =>
                              setTrigger((prev) => prev + 1)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={"section"}
                          rules={[
                            { required: true, message: " select section" },
                          ]}
                          label="Section"
                        >
                          <Select
                            style={{ width: "100%" }}
                            labelInValue={true}
                            options={sectionsData}
                            onChange={(e: any) =>
                              setTrigger((prev) => prev + 1)
                            }
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={8}>
                    <Form.Item label="Subject">
                      <Select style={{width:"100%"}}/>
                    </Form.Item>
                    </Col> */}
                    </Row>
                  </Col>

                  {/* <Col span={24}>
                  <Form.Item
                    label="Applied To"
                    name={"appliedTo"}
                    rules={[
                      {
                        required: true,
                        message: "Please select Applied To!",
                      },
                    ]}
                  >
                    <Radio.Group name="applyGroup" style={{ marginTop: 5 }}>
                      <Radio value={"all"}>All</Radio>
                      <Radio value={"particularSession"}>
                        Particular Session
                      </Radio>
                      <Radio value={"particularCourseSem"}>
                        Particular Course/semester
                      </Radio>
                      <Radio value={"particularSubject"}>
                        Particular Subject
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col> */}
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.List name="assessmentDetails" initialValue={[{}]}>
                      {(fields) => (
                        <>
                          <Table
                            size="small"
                            dataSource={fields}
                            columns={listColumns}
                            rowKey="key"
                            pagination={false}
                          />
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={addField}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Row
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>
        </Row>
        {/* <AddAssessments
          onClose={onClose}
          form={form}
          handelFile={handelFile}
          handlePreview={handlePreview}
          previewImage={previewImage}
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
          setPreviewImage={setPreviewImage}
        /> */}
      </Drawer>
    </div>
  );
};

export default AddAssessments;
