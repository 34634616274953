import {
  CaretDownOutlined,
  CaretUpOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { type } from "@testing-library/user-event/dist/type";
import {
  Button,
  Calendar,
  Col,
  ConfigProvider,
  DatePicker,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/en_GB";
import Table, { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import StudentsList from "../../../pages/Students/StudentsList";
import AttendanceService from "../../../Services/AttendanceService";
import { Utils } from "../../../Utilities/Utils";
import AddEditAttendance from "./AddEditAttendance";
import { useGetMaster } from "../../../Utilities/tanStackQueries";
import { MasterTypes } from "../../../Constant/Enums";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useDispatch, useSelector } from "react-redux";
import { ResetStudentAtt } from "../../../Store/Slice/studentAttSlice";
import { selectBatchState } from "../../../Store/Slice/batchSlice";

const { Option } = Select;

export interface IStudent {
  value: string;
  label: string;
}
interface IDayTrans {
  time: string;
  isManual: boolean;
}
export interface IStuAttendance {
  key: number;
  sno: number;
  attDate: string;
  attId: string;
  dayTrans: IDayTrans[];
  isHoliday: boolean;
  isAbsent: boolean;
  isPresent: boolean;
  isLeave:boolean;
  studyMinute: number;
  requiredMinute: number;
  in: Date;
  out: Date;
  inDiff: number;
  batchIn?:any;
  batchOut?:any;
  outDiff: number;
  abbriviation: string;
  holidayName: string;
}

const AttTableCalender = (props: { type: string,setSelectSt:any,selectSt:any }) => {
  const [trigger, setTrigger] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [batchLoader, setBatchLoader] = useState(false);
  const [batchId,setBatchId]= useState<any>("");
  const [studentListLoader, setStudentListLoader] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedStudent, setSelectedStudent] = useState<IStudent>();
  const [allStudent, setAllStudent] = useState<IStudent[]>();
  const [allBatchList, setAllBatchList] =
    useState<{ id: string; name: string }[]>();
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [stuAttendance, setStuAttendance] = useState<any>();
  const [enrolledDate, setEnrolledDate] = useState();
  const [batchDetails, setBatchDetail] = useState<any>();
  const [stuAtt, setStuAtt] = useState<{ stuId: string; attDate: string;attId:string }>();
  const [openDrawerMarkAllAtt, setOpenDrawerMarkAllAtt] = useState(false);
  const [selectStudent,setSelectStudent]= useState<any>("");
  const [batchStudent,setBatchStudent]=useState<any>("")
  const [openDrawerEditAtt, setOpenDrawerEditAtt] = useState(false);
  const [attData, setAttData] = useState([
    { type: "Present", color: "green", count: 0 },
    { type: "Absent", color: "red", count: 0 },
    { type: "Leave", color: "#b029bc", count: 0 },
    { type: "Week Offs", color: "grey", count: 0 },
  ]);
  const dispatch= useDispatch()

  // get All Student List
  useEffect(() => {
    getAllStudentList();
  }, [selectedMonth]);
  const { data: roomMaster, isLoading: isMasterLoading } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.Batch,
  }); 
  useEffect(() => {
    if (roomMaster && roomMaster?.result?.items?.length > 0) {
      let data={
        value: roomMaster?.result?.items[0].id,
        label: roomMaster?.result?.items[0].name,
      }
      if(student.batch.id!==""){
        setSelectedBatch({
          label:student?.batch?.name,
          value:student?.batch?.id
        });
        setBatchId(student?.batch?.id)
      }
      else{
        setSelectedBatch(data);
        setBatchId(data.value)
      }
    }
    
  }, [roomMaster]);
  const batchState = useSelector(selectBatchState);

  useEffect(()=>{
   
    if(student.batch.id!==""){
      setSelectStudent({
        label:student?.student?.name,
        value:student?.student?.id
      })
    }
    else if (props?.selectSt) {
      setSelectStudent(props?.selectSt)
    }
    else{
      setSelectStudent(batchStudent?.[0])
    }
  },[batchStudent])
  const getBatchOptions = () => {
    return (
      roomMaster?.result?.items.map((b: any) => {
        return {
          label: b?.name,
          value: b?.id,
        };
      }) || []
    );
  };
  
  useEffect(()=>{
    console.log("selectedBatch",batchState)
    if (batchState!=="") {
      props.setSelectSt(null)
    }
    getBatchStudent()
  },[selectedBatch,batchState])

  const student= useSelector((state:any)=>{
    return state?.student?.studentAtt
  })

  

  useEffect(() => {
    if (selectedStudent && allStudent) {
      let lastStudent = allStudent?.find(
        (item: IStudent) => item?.value === selectedStudent?.value
      );
      if (lastStudent) setSelectedStudent(lastStudent);
      else setSelectedStudent(allStudent ? allStudent[0] : undefined);
    } else {
      setSelectedStudent(allStudent ? allStudent[0] : undefined);
    }

  }, [allStudent]);

  // get attendance
  useEffect(() => {
    if (selectStudent) {
      getStuAtt(selectStudent?.value||"");
    }
  }, [trigger, selectedStudent,selectStudent]);

  const getBatchStudent = async () => {
    
    let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_BATCH_STUDENT+"?",{batchId: batchState||"" })
    if(response){
      let data = response?.result?.items.map((el:any)=>{
        // console.log("element",el);
        
        return {
          label: el.fullName,
          value: el.id
        };
      })
      setBatchStudent(data)
    }
    // console.log("response",data);

    // let res = await AttendanceService.get_att_in_sheet(params);
    // if (res) {
    //     console.log("result",res);
        
    //     setPagingItems((p) => { p.totalRecords = res?.totalRecords; return p });
    //     setDataSource(
    //       res?.items[0].students?.map((item: any, index: number) => ({
    //         key: index,
    //         sno: index + 1,
    //         name: item?.name,
    //         enrDate: item?.enrolledDate,
    //         records: item?.records,
    //       })) || []
    //     );
    //     const newAttendanceCount: Record<string, number> = {};
    //     res?.items[0].students?.forEach((student: any) => {
    //         student.records.forEach((record: any) => {
    //             if (record.attendance === 'P') {
    //                 const dateKey = record.day; // Or use your date format
    //                 if (!newAttendanceCount[dateKey]) {
    //                     newAttendanceCount[dateKey] = 0;
    //                 }
    //                 newAttendanceCount[dateKey]++;
    //             }
    //         });
    //     });
    //     setAttendanceCount(newAttendanceCount);
    //     let holidays =res?.items[0].holidaysArr;
    //     res.holidays = holidays
    //     setHolidays(holidays)
    // }
    // setIsTableLoader(false);
};
   
  const getAllStudentList = async () => {
    setPageLoader(true);
    await AttendanceService.get_all_student_list(
      selectedMonth.format("YYYY"),
      selectedMonth.format("MM"),
      selectedBatch
    ).then((res) => {
      if (res?.length > 0) {
        setAllStudent(
          res.map((r: any) => ({
            value: r?.id,
            label: r?.name,
          }))
        );
      } else {
        setAllStudent(undefined);
      }
    });
    setPageLoader(false);
  };

  const getAllBatchList = async () => {
    setBatchLoader(true);
    let res = await AttendanceService.get_batch_options();
    if (res) {
      setAllBatchList(
        res?.map((opt: { id: string; name: string }) => {
          return {
            id: opt?.id,
            name: opt?.name,
          };
        })
      );
    }
    setBatchLoader(false);
  };

  const getStuAtt = async (studentId: string) => {
    setPageLoader(true);
    await AttendanceService.get_monthly_attendance(
      studentId,
      selectedMonth.format("YYYY"),
      selectedMonth.format("MM")
    ).then((res) => {
      if (res != null) {
        console.log("attendance",res?.attendances)
        setStuAttendance(
          res?.attendances?.map((r: any, i: number) => ({
            key: i,
            attId: r?.id,
            dayTrans: r?.dayTrans?.map((item: any) => ({
              // time: dayjs.utc(item?.time),
              time: item?.time,
              isManual: item?.isManual,
            })),
            attDate: dayjs.utc(r?.attendanceDate).format("MM-DD-YYYY"),
            isHoliday: r?.isHoliday,
            isAbsent: r?.isAbsent,
            isPresent: r?.isPresent,
            isLeave: r?.isLeave,
            batchIn:r?.dayIn,
            leaveType:r?.leaveType,
            batchOut:r?.dayOut,
            inDiff: r?.inDiff,
            outDiff: r?.outDiff,
            in: Utils?.isMinDate(r?.in) ? null : dayjs.utc(r?.in).format("hh:mm A"),
            out: Utils?.isMinDate(r?.out) ? null : dayjs.utc(r?.out).format("hh:mm A"),
            requiredMinute: r?.requiredMinute,
            studyMinute: r?.studyMinute,
            add: r?.add,
            ded: r?.ded,
            holidayName: r?.holidayName,
            abbriviation: r.abbriviation,
          }))
        );
        sortByDate(stuAttendance);

        setEnrolledDate(res?.enrolledDate);
        setBatchDetail(res?.batchDetail);
        setAttData([
          { type: "Present", color: "green", count: res?.totalPresent },
          { type: "Absent", color: "red", count: res?.totalAbsent },
          { type: "Leave", color: "#b029bc", count: res?.totalLeave },
          { type: "Week Offs", color: "grey", count: res?.totalWeekOffs },
        ]);
      } else {
        setStuAttendance(undefined);
      }
      setPageLoader(false);
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "16%",
      render: (_, record) => (
        <Row>
          {record?.isHoliday ? (
            <Col className="fs13 fw600">
              {dayjs(record?.attDate, "MM-DD-YYYY")
                // .subtract(5, "hour")
                // .subtract(30, "minute")
                .format("DD MMM YYYY")}{" "}
              ({record?.holidayName})
            </Col>
          ) : 
          // record?.abbriviation === "FL" || record?.abbriviation === "HL" 
          record?.isLeave
          ? (
            <Col className="fs13 fw500" style={{ color: "#b029bc" }}>
              {dayjs(record?.attDate, "MM-DD-YYYY").format("DD MMM YYYY")} (
                <>
                {record?.isLeave && record?.leaveType})
                {console.log("recordLeave",record)}
                </>
              {/* {record?.abbriviation === "FL" ? "Full Leave" : "Half Leave"}) */}
            </Col>
          ) : record?.abbriviation === "A" ? (
            <Col className="fs13 fw500" style={{ color: "red" }}>
              {dayjs(record?.attDate, "MM-DD-YYYY").format("DD MMM YYYY")}
            </Col>
          ) : (
            <Col className="fs13 fw500">
              {" "}
              {dayjs(record?.attDate, "MM-DD-YYYY").format("DD MMM YYYY")}
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: "In Time",
      dataIndex: "in",
      width: "8%",
      render: (_, record) => (
        <Tooltip
          destroyTooltipOnHide
          placement="rightTop"
          overlayStyle={{ minWidth: 160 }}
          title={record?.dayTrans?.map((item: IDayTrans, index: number) => (
            <>
            {/* {console.log("attDatass",record)} */}
            <Row key={index} justify="space-between">
              <Col className="fs12" span={12}>
                {dayjs.utc(item?.time).format("hh:mm:ss A")}
              </Col>
              <Col className="fs12" span={11} 
              >
              
                {item?.isManual && "Manual"}
              </Col>
            </Row>
            </>
          ))}
        >
          <span
            style={{
              // color: record?.inDiff < 0 ? "red" : "#3c7d2b",
              // color: "#3c7d2b",
              // textDecoration: record?.isAbsent ? "line-through" : "",
              color: record?.in
                  ? record?.batchIn < record?.dayTrans?.[0]?.time
                    ? "rgb(255, 115, 115)"
                    : "rgb(97, 200, 85)"
                  : "black",
            }}
          >
            {record?.dayTrans?.[0]?.time?dayjs.utc(record?.dayTrans?.[0]?.time).format("hh:mm A") : "-"}
          </span>
        </Tooltip>
            
      ),
    },
    {
      title: "Out Time",
      dataIndex: "out",
      width: "8%",
      render: (_, record) => {
        
        const dayTransLength = record?.dayTrans?.length || 0;
        // console.log(record.batchOut,"OutRecord",dayjs(record?.dayTrans?.[dayTransLength - 1]?.time).format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        return(
        <Tooltip
          destroyTooltipOnHide
          placement="rightTop"
          overlayStyle={{ minWidth: 160 }}
          title={record?.dayTrans?.map((item: IDayTrans, index: number) => (
            <Row key={index} justify="space-between">
              <Col className="fs12" span={12}>
                {dayjs.utc(item?.time).format("hh:mm:ss A")}
              </Col>
              <Col className="fs12" span={11}>
                {item?.isManual && "Manual"}
              </Col>
            </Row>
          ))}
        >
          <span
            style={{
              // color: record?.outDiff < 0 ? "red" : "#3c7d2b",
              // color: "#3c7d2b",
              color: dayTransLength % 2 === 0 && dayTransLength !== 0
                    ? record?.batchOut >
                    record?.dayTrans?.[dayTransLength - 1]?.time
                      ? "rgb(255, 115, 115)"
                    : "rgb(97, 200, 85)"
                    : "black",
              // textDecoration: record?.isAbsent ? "line-through" : "",
            }}
          >
             {dayTransLength % 2 === 0 && dayTransLength !== 0
                  ? dayjs.utc
                      (record?.dayTrans[dayTransLength - 1]?.time)
                      .format("hh:mm A")
                  // ?record?.out
                  : "-"}
            {/* {record?.out !== null ? dayjs(record?.out).format("hh:mm A") : ""} */}
          </span>
        </Tooltip>

        )
        
      },
    },
    // {
    //   title: "Required Hours",
    //   dataIndex: "rm",
    //   width: "13%",
    //   render: (_, record) => (
    //     <p style={{ textDecoration: record?.isAbsent ? "line-through" : "" }}>
    //       {record?.requiredMinute
    //         ? Utils.minutesIntoHours(record?.requiredMinute)
    //         : ""}
    //     </p>
    //   ),
    // },
    // {
    //   title: "Present Hours",
    //   dataIndex: "wm",
    //   width: "13%",
    //   render: (_, record) => (
    //     <p style={{ textDecoration: record?.isAbsent ? "line-through" : "" }}>
    //       {record?.studyMinute
    //         ? Utils.minutesIntoHours(record?.studyMinute)
    //         : ""}
    //     </p>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "",
      width: "40%",
      render: (_, record) => (
        <>
          <Tag
            color={getTagData(record, "type")}
            style={{
              cursor: "pointer",
              textAlign: "center",
              fontWeight: 500,
              fontSize: 12,
            }}
            onClick={() => {
              if (
                dayjs(record?.attDate, "MM-DD-YYYY")?.isAfter(dayjs(), "date")
              ) {
                return false;
              } else {
                console.log("record",record)
                record?.attId?
                editAttendance(dayjs(record?.attDate, "MM-DD-YYYY"),record?.attId): editAttendance(dayjs(record?.attDate, "MM-DD-YYYY"),"")
                
              }
            }}
          >
            {getTagData(record, "label")}
          </Tag>
        </>
      ),
    },
  ];
  // console.log("data",selectStudent);
  
  const editAttendance = (date: Dayjs,attId?:any) => {
    if (selectStudent) {  //if add seprate student add selectStudent
      setStuAtt({
        stuId: selectStudent?.value,
        attDate: date.format("MM-DD-YYYY"),
        attId
      });
    }
    setOpenDrawerEditAtt(true);
  };

  const getTagData = (record: IStuAttendance, type: string) => {
    if (record?.isPresent) {
      if (type === "bg-color") {
        return "#00800014";
      }
      if (type === "border-color") {
        return "#008000bd";
      } else if (type === "label-color") {
        return "green";
      } else if (type === "type") {
        return "success";
      } else if (type === "label") {
        return "Present";
      } else {
        return "Present";
      }
    }else if (record?.isHoliday) {
      if (type === "bg-color") {
        return "#EBEDFF";
      } else if (type === "bg-btn") {
        return "#EBEDFF";
      } else if (type === "color-btn") {
        return "#5364FF";
      } else if (type === "light-btn") {
        return "#ccd1ff";
      } else if (type === "type") {
        return "";
      } else if (type === "label") {
        return "Holiday";
      } else {
        return "Holiday";
      }
    } 
    else if(record?.isLeave) {
      if (type==="label") {
        return " Leave"
      }
      else if (type==="type") {
        return "#b029bc"
      }
      else if(type==="label-color"){
        return "#b029bc"
      }
      else if(type==="border-color"){
        return "#d567dc"
      }
    }
    else if (record?.isAbsent) {
      if (type === "bg-color") {
        return "#ff00001a";
      }
      if (type === "border-color") {
        return "#ff000094";
      } else if (type === "label-color") {
        return "#ff000a";
      } else if (type === "type") {
        return "error";
      } else if (type === "label") {
        return "Absent";
      } else {
        return "Absent Marked";
      }
    } 
    else {
      if (type === "bg-color") {
        return "#ff00001a";
      }
      if (type === "border-color") {
        return "#ff000094";
      } else if (type === "label-color") {
        return "#ff000a";
      } else if (type === "type") {
        return "error";
      } else if (type === "label") {
        return "Absent";
      } else {
        return "Absent";
      }
    }
  };

  const dateCellRender = (value: Dayjs) => {
    const date = value.format("MM-DD-YYYY");
    const isToday = date === dayjs().format("MM-DD-YYYY");
    const data = stuAttendance?.find(
      (item: any) => item?.attDate === date
    );
    // if (date === dayjs("11-06-2023").format("MM-DD-YYYY"))

    if (value.month() === dayjs(selectedMonth).month()) {
      if (value.isBefore(dayjs().format("DD"), "date")) {
        return (
          <Row
            className="att-calender-cell"
            title={value?.format("DD MMM YYYY")}
          >
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
          </Row>
        );
      }
      // shows Absent , Present or Leave
      if (
        value.isAfter(dayjs(), "date") &&
        (data?.attId ||
          (data?.isHoliday &&
            !batchDetails?.batchDays
              ?.filter((item: any) => !item?.isChecked)
              ?.some((item: any) => item?.day - 1 === value?.day())))
      ) {
        return (
          <Row
            className={
              isToday ? "att-calender-cell isToday" : "att-calender-cell"
            }
            title={value?.format("DD MMM YYYY")}
          >
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
            <Col span={24} className="att-calender-cell-inner-box">
              <Row align="middle" className="mb-1">
                <Col
                  className="fw500 fs12 px-1 roundedCornerSmall att-calender-cell-data"
                  style={{
                    backgroundColor: getTagData(data, "bg-color"),
                    color: getTagData(data, "color-btn"),
                  }}
                  title={data?.holidayName}
                >
                  <span className="ms-1"> {getTagData(data, "status")}{`  (${data?.holidayName})`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }
      // for holidays
      else if (
        value.isAfter(dayjs(), "date") &&
        batchDetails?.batchDays
          ?.filter((item: any) => !item?.isChecked)
          ?.some((item: any) => item?.day - 1 === value?.day())
      ) {
        return (
          <Row
            className={
              isToday ? "att-calender-cell isToday" : "att-calender-cell"
            }
            title={value?.format("DD MMM YYYY")}
            style={{ backgroundColor: "#F4F4F8" }}
          >
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
            <Col span={24} className="att-calender-cell-inner-box ps-3">
              <Row className="fw500 fs12" align="middle">
                Week Off
              </Row>
            </Col>
          </Row>
        );
      } else if ((value.isAfter(dayjs(), "date"))&& data?.isLeave) {
        return (
          <Row
            className="att-calender-cell"
            title={value?.format("DD MMM YYYY")}
          >
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
          <div
                      style={{
                        height: "25%",
                        background: getTagData(data, "border-color"),
                        width: 5,
                        borderRadius: "0px 6px 6px 0px",
                      }}
                    ></div>
                    <div className="px-2" style={{ padding: "2px 8px" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: getTagData(data, "label-color"),
                        }}
                      >
                        {getTagData(data, "label")}
                      </p>
                    </div>
          </Row>
         
        );
      }else if  (value.isAfter(dayjs(), "date")){
        return (
          <Row
            className="att-calender-cell"
            title={value?.format("DD MMM YYYY")}
          >
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
          </Row>
        );
      }

      if (
        date === data?.attDate &&
        !batchDetails?.batchDays
          ?.filter((item: any) => !item?.isChecked)
          ?.some((item: any) => item?.day - 1 === value?.day())
      ) {
        return (
          <Row
            className={
              isToday ? "att-calender-cell isToday" : "att-calender-cell"
            }
            title={value?.format("DD MMM YYYY")}
          >
            <Col span={24} className="px-3 pt-2 pb-2 fw600 fs15">
              <Row justify="space-between">
                <Col>{value.format("DD")}</Col>
                {!isToday &&
                  data?.dayTrans &&
                  data?.dayTrans?.length % 2 !== 0 && (
                    <ExclamationCircleOutlined
                      title="Out time missing"
                      style={{ color: "#d31212" }}
                    />
                  )}
              </Row>
            </Col>
            <Col span={24} className="att-calender-cell-inner-box">
              <div
                className="mb-1"
                style={{
                  display: "flex",
                  width: "fit-content",
                  background: getTagData(data, "bg-color"),
                  borderRadius: "0px 6px 6px 0px",
                }}
              >
                {isToday && data?.abbriviation === "A" ? (
                  ""
                ) : (
                  <>
                    <div
                      style={{
                        height: "100%",
                        background: getTagData(data, "border-color"),
                        width: 5,
                        borderRadius: "0px 6px 6px 0px",
                      }}
                    ></div>
                    <div className="px-2" style={{ padding: "2px 8px" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: getTagData(data, "label-color"),
                        }}
                      >
                        {getTagData(data, "label")}
                      </p>
                    </div>
                  </>
                )}
              </div>

              {data?.dayTrans && !data?.isAbsent && (
                <Tooltip
                  color="black"
                  destroyTooltipOnHide
                  overlayInnerStyle={{ color: "black" }}
                  placement="topRight"
                  overlayStyle={{ minWidth: 180 }}
                  title={
                    <>
                      {data?.dayTrans?.map((item: any, index: number) => (
                        <Row key={index} justify="space-between">
                          <Col className="fs13" span={12}>
                            {dayjs.utc(item?.time).format("hh:mm:ss A")}
                          </Col>

                          <Col className="fs13" span={11}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>{item?.isManual && "Manual"}</span>
                              <span>
                                {index % 2 === 0 ? (
                                  <CaretDownOutlined
                                    style={{ color: "lightGreen" }}
                                  />
                                ) : (
                                  <CaretUpOutlined
                                    style={{ color: "lightPink" }}
                                  />
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      ))}
                      {/* <Row
                        style={{
                          borderTop: "1px solid black",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Col className="fs13 fw600">Total :</Col>
                        <Col className="fs13 fw600 ms-1">
                          {Utils.minutesIntoHours(data?.studyMinute)} hrs
                        </Col>
                      </Row> */}
                    </>
                  }
                >
                  <Row className="ms-2">
                    <Col
                      span={11}
                      className="fs11"
                      style={{ color: data.in
                        ? data?.batchIn < data?.dayTrans?.[0].time
                          ? "rgb(255, 115, 115)"
                          : "rgb(97, 200, 85)"
                        : "black",}}
                    >
                      <CaretDownOutlined
                        title="In Time"
                        className="me-1"
                        style={{ color: "#8ce395", fontSize: 13 }}
                      />
                      {data?.in}
                    </Col>
                    {data?.out === null ? (
                      <></>
                    ) : (
                      <Col
                        span={11}
                        className="fs11"
                        style={{ color: data?.dayTrans?.length% 2 === 0 
                          ? data?.batchOut >
                            data?.dayTrans?.[data?.dayTrans?.length - 1]?.time
                            ? "rgb(255, 115, 115)"
                          : "rgb(97, 200, 85)"
                          : "red" }}
                      >
                        <CaretUpOutlined
                          title="Out Time"
                          className="me-1"
                          style={{ color: data?.dayTrans?.length% 2 === 0 
                            ? data?.batchOut >
                              data?.dayTrans?.[data?.dayTrans?.length - 1]?.time
                              ? "rgb(255, 115, 115)"
                            : "rgb(97, 200, 85)"
                            : "red", fontSize: 13 }}
                        />
                        {data?.out === null
                          ? ""
                          : data?.out}
                      </Col>
                    )}
                  </Row>
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      } else if (
        batchDetails?.batchDays
          ?.filter((item: any) => !item?.isChecked)
          ?.some((item: any) => item?.day - 1 === value?.day())
      ) {
        return (
          <Row
            className={
              isToday ? "att-calender-cell isToday" : "att-calender-cell"
            }
            title={value?.format("DD MMM YYYY")}
            style={{ backgroundColor: "#F4F4F8" }}
          >
            <Col span={24} className="px-3 pt-2 pb-2 fw600 fs15">
              <Row justify="space-between">
                <Col>{value.format("DD")}</Col>
                {!isToday &&
                  data?.dayTrans &&
                  data?.dayTrans?.length % 2 !== 0 && (
                    <ExclamationCircleOutlined
                      title="Out time missing"
                      style={{ color: "#d31212" }}
                    />
                  )}
              </Row>
            </Col>
            <Col span={24} className="att-calender-cell-inner-box ps-3">
              <Row className="fw500 fs12" align="middle">
                Week Off
              </Row>
              {data?.dayTrans && !data?.isAbsent && (
                <Tooltip
                  color="#e6f4ff"
                  destroyTooltipOnHide
                  overlayInnerStyle={{ color: "black" }}
                  placement="topRight"
                  overlayStyle={{ minWidth: 180 }}
                  title={
                    <>
                      {data?.dayTrans?.map((item: any, index: number) => (
                        <Row key={index} justify="space-between">
                          <Col className="fs13" span={12}>
                            {dayjs.utc(item?.time).format("hh:mm:ss A")}
                          </Col>
                          <Col className="fs13" span={11}>
                            {item?.isManual && "Manual"}
                          </Col>
                        </Row>
                      ))}
                      <Row className="border-top border-bottom">
                        <Col className="fs13 fw600">Total :</Col>
                        <Col className="fs13 fw600 ms-1">
                          {Utils.minutesIntoHours(data?.studyMinute)} hrs
                        </Col>
                      </Row>
                    </>
                  }
                >
                  
                  <Row>
                    <Col
                      span={11}
                      className="fs11"
                      style={{ color: data.inDiff < 0 ? "red" : "#3c7d2b" }}
                    >
                      <CaretDownOutlined
                        title="In Time"
                        className="me-1"
                        style={{ color: "#8ce395", fontSize: 13 }}
                      />
                      {data?.in}
                    </Col>
                    {data?.out === null ? (
                      <></>
                    ) : (
                      <Col
                        span={11}
                        className="fs11"
                        style={{ color: data.outDiff < 0 ? "red" : "#3c7d2b" }}
                      >
                        <CaretUpOutlined
                          title="Out Time"
                          className="me-1"
                          style={{ color: "#fa81b4", fontSize: 13 }}
                        />
                        {data?.out === null
                          ? ""
                          : data?.out}
                      </Col>
                    )}
                  </Row>
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      } else {
        return (
          <Row className="att-calender-cell">
            <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
              {value.format("DD")}
            </Col>
          </Row>
        );
      }
    } else {
      return (
        <Row className="att-calender-cell">
          <Col span={24} className="ps-3 pt-2 pb-2 fw600 fs15">
            {value.format("DD")}
          </Col>
        </Row>
      );
    }
  };
  

  const sortByDate = (st: IStuAttendance[] | undefined) => {
    let dateObjects: any = st?.map((dateString) => ({
      ...dateString,
      attDate: new Date(dateString?.attDate),
    }));
    dateObjects?.sort(
      (a: any, b: any) => a?.attDate?.getTime() - b?.attDate?.getTime()
    );
    let x = dateObjects?.map((x: any) => ({
      ...x,
      attDate: dayjs(x?.attDate),
    }));
    return x;
  };
  return (
    <ConfigProvider
    theme={{
      components: {
        Table: {
          borderColor: "#bfbfbf"
        }
      }
    }}
  >
    <Spin
      spinning={pageLoader}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <AddEditAttendance
        stuId={stuAtt?.stuId}
        attDate={stuAtt?.attDate}
        attId={stuAtt?.attId}
        setTrigger={setTrigger}
        drawerOpen={openDrawerEditAtt}
        setDrawerClose={setOpenDrawerEditAtt}
      />
      <Row className="py-3 px-3 shadowLight roundedCornerSmall bg-white">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              {allStudent && (
                <>
                  {/* <Select
                  loading={isMasterLoading}
                    popupClassName="cit-select-box"
                    className="me-2"
                    placeholder={"Select Batch"}
                    allowClear
                    showSearch
                    labelInValue
                    value={selectedBatch}
                    options={getBatchOptions()}
                    style={{ width: 160 }}
                    onSelect={(val: any) => {
                      setSelectedBatch(val)
                      setBatchId(val?.value)
                      dispatch(ResetStudentAtt())
                    }}
                    filterOption={(input, option:any) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.trim().toLowerCase())
                    }
                    /> */}

                  <Select
                    popupClassName="cit-select-box"
                    className="me-2 mb-1"
                    showSearch
                    placeholder={"Select Student"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    allowClear={false}
                    labelInValue
                    size="middle"
                    onChange={(val) => {
                      dispatch(ResetStudentAtt())
                      props.setSelectSt({
                        value: val?.value,
                        label: val?.label,
                      })
                      setSelectedStudent({
                        value: val?.value,
                        label: val?.label,
                      });
                      setSelectStudent({
                        value: val?.value,
                        label: val?.label,
                      });
                      
                    }}
                    // value={batchStudent}
                    filterOption={(input, option:any) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.trim().toLowerCase())
                    }
                    value={selectStudent}
                    options={batchStudent}
                  />
                </>
              )}

              <DatePicker
                picker="month"
                size="middle"
                style={{ width: 105 }}
                format="MMM YYYY"
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
                value={selectedMonth}
                className="att-date-input mb-1"
                allowClear={false}
                onChange={(date: any) => {
                  setSelectedMonth(date);
                }}
                popupClassName="ttp-custom-time-picker"
              />
            </Col>
            <Col></Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            {allStudent ? (
              <Col span={24} style={{ margin: "12px 0px 16px 0px" }}>
                <Row gutter={[16, 16]}>
                  {attData?.map((item: any, index: number) => (
                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      xxl={6}
                      key={index}
                    >
                      <Row
                        className="att-detail-box"
                        style={{ borderLeft: `6px solid ${item?.color}` }}
                      >
                        {/*  <div style={{ background: item?.color, width: 6, borderRadius: 8 }}></div>*/}
                        <div>
                          <p
                            style={{
                              color: item?.color,
                              fontSize: 18,
                              fontWeight: 600,
                            }}
                          >
                            {item?.count}
                          </p>
                          <p style={{ opacity: "70%", fontSize: 13 }}>
                            {item?.type}
                          </p>
                        </div>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col span={24}>
          <Row className="pt-2">
            {allStudent ? (
              <Col span={24} style={{ overflow: "auto"}}>
                {props.type === "table" ? (
                  <Table
                    rowClassName={(record) =>
                      dayjs(record?.attDate).isBefore(enrolledDate, "date")
                        ? "disabled-row"
                        : dayjs(record.attDate).format("DD/MM/YYYY") ===
                          dayjs().format("DD/MM/YYYY")
                          ? "table-row-today"
                          : record?.abbriviation !== "P"
                            ? "table-row-light"
                            : "table-row-dark"
                    }
                    columns={columns}
                    className="cit-table"
                    style={{minHeight:630}}
                    dataSource={sortByDate(stuAttendance)}
                    scroll={{ x: 700, y: 550 }}
                    size="small"
                    pagination={false}
                    summary={() => {
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} className="fw600">
                              Total Days: {stuAttendance?.length}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={1}
                              className="fw600"
                            ></Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={2}
                              className="fw600"
                            ></Table.Summary.Cell>
                            {/* <Table.Summary.Cell index={3} className="fw600">
                              {Utils.minutesIntoHours(
                                stuAttendance
                                  ?.filter((item) => !item.isAbsent)
                                  ?.reduce((acc, curr) => {
                                    return (acc += Number(curr.requiredMinute));
                                  }, 0) ?? 0
                              )}{" "}
                              Hrs
                            </Table.Summary.Cell> */}
                            {/* <Table.Summary.Cell index={4} className="fw600">
                              {Utils.minutesIntoHours(
                                stuAttendance
                                  ?.filter((item) => !item.isAbsent)
                                  ?.reduce((acc, curr) => {
                                    return (acc += Number(curr.studyMinute));
                                  }, 0) ?? 0
                              )}{" "}
                              Hrs
                            </Table.Summary.Cell> */}
                            <Table.Summary.Cell
                              index={7}
                              className="fw600"
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      );
                    }}
                  />
                ) : (
                  <Calendar
                    locale={locale}
                    className="att-detail-cal"
                    style={{ minWidth: 1200 }}
                    fullCellRender={dateCellRender}
                    headerRender={() => {
                      return undefined;
                    }}
                    value={selectedMonth}
                    disabledDate={(current: Dayjs) => {
                      if (
                        current.month() === dayjs(selectedMonth).month() &&
                        !current.isBefore(enrolledDate, "date")
                      ) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    onSelect={(date,record) => {
                      if (date?.isAfter(dayjs(), "date")) {
                        return false;
                      } else {
                        const selectedDate = date.format('MM-DD-YYYY');
                          const record = stuAttendance.find((item:any) => item.attDate === selectedDate);
                        editAttendance(date,record?.attId);
                      }
                    }}
                  />
                )}
              </Col>
            ) : !pageLoader && !allStudent ? (
              <p>No students found</p>
            ) : (
              <p></p>
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  </ConfigProvider>
  );
};
export default AttTableCalender;
